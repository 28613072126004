@import 'pages/dashboards/dashboard1';
@import 'pages/dashboards/dashboard5';
@import 'pages/dashboards/dashboard6';
@import 'pages/dashboards/dashboard8';
@import 'pages/dashboards/dashboard9';
@import 'pages/authentication/authentication';
@import 'pages/forms/typeahead';
.container {
    margin-left: 40px;
    width: 100%;
}