// scafholding
* {
    outline: none;
}

body {
    margin: 0;
    overflow-x: hidden;
    color: $body-color;
    background: $main-body-bg;
}

html {
    position: relative;
    min-height: 100%;
}

a {
    color: $themecolor;
}

a:hover,
a:focus {
    text-decoration: none;
}

a.link {
    color: $body-color;
    &:hover,
    &:focus {
        color: $themecolor;
    }
}

.box {
    border-radius: $border-radius;
    padding: 10px;
}


.no-wrap {
    td,
    th {
        white-space: nowrap;
    }
}



/*******************
Blockquote
*******************/

html body blockquote {
    border-left: 5px solid $primary;
    border: 1px solid $border-color;
    padding: 15px;
}

.clear {
    clear: both;
}

ol li {
    margin: 5px 0;
}


/*******************
Thumb size
*******************/

.thumb-sm {
    height: 32px;
    width: 32px;
}

.thumb-md {
    height: 48px;
    width: 48px;
}

.thumb-lg {
    height: 88px;
    width: 88px;
}

.hide {
    display: none;
}

.radius {
    border-radius: $border-radius;
}


/*******************
Opacity
*******************/

.op-5 {
    opacity: 0.5;
}

.op-3 {
    opacity: 0.3;
}



/*******************
font weight
*******************/

html body {
    .font-bold {
        font-weight: $font-weight-bold;
    }
    .font-normal {
        font-weight: $font-weight-normal;
    }
    .font-light {
        font-weight: $font-weight-light;
    }
    .font-medium {
        font-weight: $font-weight-medium;
    }
    .font-16 {
        font-size: 16px;
    }
    .font-12 {
        font-size: 12px;
    }
    .font-14 {
        font-size: 14px;
    }
    .font-10 {
        font-size: 10px;
    }
    .font-18 {
        font-size: 18px;
    }
    .font-20 {
        font-size: 20px;
    }
    .font-22 {
        font-size: 22px;
    }
    .font-24 {
        font-size: 24px;
    }
    .display-5 {
        font-size: 3rem;
    }
    .display-6 {
        font-size: 2.5rem;
    }
    .display-7 {
        font-size: 2rem;
    }
}



/*******************
Background colors
*******************/

html body {
    .bg-megna {
        background-color: $cyan;
    }
    .bg-theme {
        background-color: $themecolor;
    }
    .bg-inverse {
        background-color: $gray-900;
    }
    .bg-purple {
        background-color: $purple;
    }
    .bg-light {
        background-color: $gray-200;
    }
    .bg-white {
        background-color: $white;
    }
}



/*******************
Rounds
*******************/

.round {
    line-height: 48px;
    color: $white;
    width: 50px;
    height: 50px;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    border-radius: 100%;
    background: $blue;
    line-height: 52px;
    img {
        border-radius: 100%;
    }
    &.round-info {
        background: $blue;
    }

    &.round-warning {
        background: $warning;
    }

    &.round-danger {
        background: $danger;
    }

    &.round-success {
        background: $success;
    }

    &.round-primary {
        background: $primary;
    }
}

.round-lg {
    line-height: 65px;
    width: 60px;
    height: 60px;
    font-size: 30px;
}





/*******************
Labels
*******************/

.label-rounded {
    border-radius: 60px;
}



/*******************
 Badge
******************/

.badge {
    font-weight: $font-weight-light;
    line-height: normal;
    &.badge-pill {
        padding: 0.20em 0.6em;
    }
}

.badge-xs {
    font-size: 9px;
}

.badge-xs,
.badge-sm {
    -webkit-transform: translate(0, -2px);
    -ms-transform: translate(0, -2px);
    -o-transform: translate(0, -2px);
    transform: translate(0, -2px);
}



/*******************
List-style-none
******************/

ul.list-style-none {
    margin: 0px;
    padding: 0px;
    li {
        list-style: none;
        a {
            color: $body-color;
            padding: 8px 0px;
            display: block;
            text-decoration: none;
            &:hover {
                color: $themecolor;
            }
        }
    }
}


/*******************
Card
******************/

.card {
    margin-bottom: 20px;
    .card-subtitle {
        font-weight: $font-weight-light;
        margin-bottom: 10px;
        color: $text-muted;
    }
    .card-title {
        position: relative;
        font-weight: $font-weight-medium;
        margin-bottom: 10px;
    }
    .card-actions {
        float: $rgt;
        a {
            padding: 0 5px;
            cursor: pointer;
        }
    }
    .card-header .card-title{
        margin-bottom: 0px;
    }
}

.card-alt {
    margin: 0 -20px;
    background: $card-alt;
}

.card-group {
    margin-bottom: 20px;
    .card {
        border-right: 1px solid $border-color;
    }
}

.card-fullscreen {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    overflow: auto;
}

.oh {
    overflow: hidden;
}
.card-hover{
    -webkit-transition: all .25s ease;
    -o-transition: all .25s ease;
    -moz-transition: all .25s ease;
    transition: all .25s ease;
    &:hover{
        webkit-transform: translateY(-4px) scale(1.01);
        -moz-transform: translateY(-4px) scale(1.01);
        -ms-transform: translateY(-4px) scale(1.01);
        -o-transform: translateY(-4px) scale(1.01);
        transform: translateY(-4px) scale(1.01);
        -webkit-box-shadow: 0 14px 24px rgba(62,57,107,.1);
        box-shadow: 0 14px 24px rgba(62,57,107,.1);
    }
    .amount{
        font-size: 18px;
        font-weight: bold;
        margin-top: -26px;
        margin-left: 70px;
    }
}
.draggable-cards .card-header{
    cursor: move;
}
.card-moved .card{
    background: $info;
    color:$white;
}
/*******************
Labels
*******************/

.label {
    padding: 3px 10px;
    line-height: 13px;
    color: $white;
    font-weight: 400;
    border-radius: $border-radius;
    font-size: 75%;
}

.label-rounded {
    border-radius: 60px;
}

.label-custom {
    background-color: $cyan;
}

.label-success {
    background-color: $success;
}

.label-info {
    background-color: $blue;
}

.label-warning {
    background-color: $warning;
}

.label-danger {
    background-color: $danger;
}

.label-megna {
    background-color: $cyan;
}

.label-primary {
    background-color: $primary;
}

.label-purple {
    background-color: $purple;
}

.label-red {
    background-color: $red;
}

.label-inverse {
    background-color: $gray-800;
}

.label-default {
    background-color: $gray-100;
}
